// User reducer types
export const SET_AUTHENTICATED = 'SET_AUTHENTICATED';
export const SET_UNAUTHENTICATED = 'SET_UNAUTHENTICATED';
export const SET_USER = 'SET_USER';
export const LOADING_USER = 'LOADING_USER';

// UI reducer types
export const SET_ERRORS = 'SET_ERRORS';
export const LOADING_UI = 'LOADING_UI';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const STOP_LOADING_UI = 'STOP_LOADING_UI';

// Data reducer types
export const SET_ACCOUNTS = 'SET_ACCOUNTS';
export const SET_ACCOUNT = 'SET_ACCOUNT';
export const LOADING_DATA = 'LOADING_DATA';
export const CREATE_ACCOUNT = 'CREATE_ACCOUNT';
export const SUBMIT_TRANSACTION = 'SUBMIT_TRANSACTION';
export const SET_TRANSACTION = 'SET_TRANSACTION';
export const SET_TRANSACTIONS = 'SET_TRANSACTIONS';



