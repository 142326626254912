import {
  SET_ACCOUNTS,
  SET_ACCOUNT,
  LOADING_DATA,
  SET_ERRORS,
  CLEAR_ERRORS,
  CREATE_ACCOUNT,
  SUBMIT_TRANSACTION,
  SET_TRANSACTION,
  SET_TRANSACTIONS,
  LOADING_UI,
  STOP_LOADING_UI,
} from "../types";
import axios from "axios";

export const getAccounts = () => (dispatch) => {
  dispatch({ type: LOADING_DATA });
  console.log("Getting accounts...");
  axios
    .get("/accounts")
    .then((res) => {
      dispatch({
        type: SET_ACCOUNTS,
        payload: res.data,
      });
      console.log("Got the accounts: ", res.data);
    })
    .catch((err) => {
      dispatch({
        type: SET_ACCOUNTS,
        payload: [],
      });
      console.log("Encountered error: ", err);
    });
};

export const getAccount = (accountId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .get(`/account/${accountId}`)
    .then((res) => {
      dispatch({
        type: SET_ACCOUNT,
        payload: res.data,
      });
      dispatch({ type: STOP_LOADING_UI });
    })
    .catch((err) => console.log(err));
};

export const getTransactions = (accountId) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios.get(`/account/${accountId}/transactions`).then((res) => {
    dispatch({
      type: SET_TRANSACTIONS,
      payload: res.data,
    });
    dispatch({ type: STOP_LOADING_UI });
  }).catch((err) => console.log(err));
};

export const createAccount = (newAccount) => (dispatch) => {
  dispatch({ type: LOADING_UI });
  axios
    .post("/createAccount", newAccount)
    .then((res) => {
      dispatch({
        type: CREATE_ACCOUNT,
        payload: res.data,
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const submitTransaction = (accountId, transactionData) => (dispatch) => {
  console.log("account", accountId);
  console.log("Transaction:", transactionData);
  axios
    .post(`/account/${accountId}/createTransaction`, transactionData)
    .then((res) => {
      dispatch({
        type: SUBMIT_TRANSACTION,
        payload: res.data,
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      console.log("oh no, an error:", err);
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};

export const getTransaction = (accountId, transactionId) => (dispatch) => {
  axios
    .get(`/account/${accountId}/transaction/${transactionId}`)
    .then((res) => {
      dispatch({
        type: SET_TRANSACTION,
        payload: res.data,
      });
      dispatch({ type: CLEAR_ERRORS });
    })
    .catch((err) => {
      dispatch({
        type: SET_ERRORS,
        payload: err.response.data,
      });
    });
};
