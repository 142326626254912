import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import { submitTransaction } from "../redux/actions/dataActions";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import Typography from "@material-ui/core/Typography";
import FormControl from "@material-ui/core/FormControl";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import CurrencyTextField from "@unicef/material-ui-currency-textfield";

const styles = {
  typography: {
    useNextVariants: true,
  },
  form: {
    textAlign: "center",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  textField: {
    margin: "20px auto 20px auto",
  },
  button: {
    margin: 20,
    position: "relative",
    color: "primary",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 15,
  },
  progress: {
    position: "absolute",
  },
};

class NewTransaction extends Component {
  constructor() {
    super();

    this.state = {
      amount: 0,
      currency: "USD",
      description: "",
      transactionType: "deposit",
      multiplier: 1,
      open: false,
      errors: {},
    };
  }
  componentDidMount() {
    this.setState({ accountId: this.props.accountId });
  }

  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ amount: 0, description: "", transactionType: "deposit", multiplier: 1, open: false, errors: {} });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
    });
    console.log("Change:", event.target.name, event.target.value);
  };
  handleRadioChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      multiplier: event.target.value === "withdrawal" ? -1 : 1,
    });
    console.log("Transaction type: ", this.state.transactionType);
    console.log("Multiplier ", this.state.multiplier);
  };
  handleSubmit = (event) => {
    event.preventDefault();
    let transaction = {
      accountId: this.props.accountId,
      amount:
        Number(this.state.amount.replace(/,/g, "")) * this.state.multiplier,
      currency: this.state.currency,
      description: this.state.description,
    };
    console.log("inputs:", transaction);
    this.props.submitTransaction(this.props.accountId, transaction);
    this.handleClose();
  };
  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <Fragment>
        <Tooltip title="Submit a new transaction" placement="top">
          <Button onClick={this.handleOpen} className={classes.button} variant="contained" color="primary">
            New Transaction
          </Button>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Submit a new transaction: </DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit} autoComplete="off">
              <TextField
                name="description"
                type="text"
                label="Description"
                autoComplete='off'
                className={classes.textField}
                value={this.state.description}
                onChange={this.handleChange}
                fullWidth
              />
              <CurrencyTextField
                name="amount"
                variant="standard"
                autoComplete='off'
                currencySymbol="$"
                outputFormat="number"
                type="text"
                inputmode="decimal"
                label="amount"
                placeholder="0.00"
                className={classes.textField}
                value={this.value}
                onChange={this.handleChange}
                fullWidth
              />
              <FormControl component="fieldset">
                <FormLabel component="legend">Type:</FormLabel>
                <RadioGroup
                  aria-label="type"
                  name="transactionType"
                  value={this.state.transactionType}
                  onChange={this.handleRadioChange}
                >
                  <FormControlLabel
                    value="deposit"
                    control={<Radio color="primary" />}
                    label="Deposit"
                  />
                  <FormControlLabel
                    value="withdrawal"
                    control={<Radio color="primary"/>}
                    label="Withdrawal"
                  />
                </RadioGroup>
              </FormControl>
            </form>
            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={this.handleSubmit} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

NewTransaction.propTypes = {
  submitTransaction: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  accountId: state.data.account.accountId,
  amount: state.amount,
  currency: state.currency,
  description: state.description,
});

export default connect(mapStateToProps, { submitTransaction })(
  withStyles(styles)(NewTransaction)
);
