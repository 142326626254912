import React, { Component } from "react";
import { Link } from 'react-router-dom';
import withStyles from "@material-ui/core/styles/withStyles";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemAvatar from "@material-ui/core/ListItemAvatar";
import Avatar from "@material-ui/core/Avatar";
import ListItemSecondaryAction from "@material-ui/core/ListItemSecondaryAction";
import IconButton from "@material-ui/core/IconButton";
import ArrowForwardIos from "@material-ui/icons/ArrowForwardIos";
import Paper from "@material-ui/core/Paper";

import { connect } from "react-redux";

let accountImage = "../images/no-img.png";

const styles = {
  card: {
    display: "flex",
  },
  root: {
    width: "100%",
    maxWidth: 345,
  },
  balance: {
    position: "absolute",
    right: "16px",
  },
};

class Accountsummary extends Component {
  render() {
    const {
      account: { accountId, accountName, accountBalance }
      
    } = this.props;

    return (
      <Paper id={accountId}>
        <ListItem alignItems="flex-start" >
          <ListItemAvatar>
            <Avatar src={accountImage} />
          </ListItemAvatar>
          <ListItemText
            primary={accountName}
            secondary={
              "Balance: " +
              Intl.NumberFormat("en-US", {
                style: "currency",
                currency: "USD",
              }).format(accountBalance)
            }
          />
          <ListItemSecondaryAction>
            <IconButton edge="end" aria-label="details" component={Link} to={`/account/${accountId}`}>
              <ArrowForwardIos />
            </IconButton>
          </ListItemSecondaryAction>
        </ListItem>
      </Paper>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
});

export default connect(mapStateToProps)(withStyles(styles)(Accountsummary));
