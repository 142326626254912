import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import MyButton from '../util/MyButton';
import SignOut from './SignOut';

// Material UI
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Button from '@material-ui/core/Button';

// Icons
import HomeIcon from '@material-ui/icons/Home';


export class Navbar extends Component {
  render() {
    const { authenticated } = this.props;
    return (
      <AppBar>
        <Toolbar className="nav-container">
          {authenticated ? (
            <Fragment>
              <Link to="/">
              <MyButton tip="Home">
                <HomeIcon style={{ color:"#FFF" }}  />
              </MyButton>
            </Link>
            <SignOut />
            </Fragment>
          ) :
          <Fragment>
            <Link to="/">
              <MyButton tip="Home">
                <HomeIcon style={{ color:"#FFF" }} />
              </MyButton>
            </Link>
            <Button color='inherit' component={Link} to="/signin">Sign In</Button>
            <Button color='inherit' component={Link} to="/signup">Sign Up</Button>
          </Fragment>
          }
        </Toolbar>
      </AppBar>
    )
  }
}

Navbar.propTypes = {
  authenticated: PropTypes.bool.isRequired
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated
});

export default connect(mapStateToProps)(Navbar);
