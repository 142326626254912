import React, { Component, Fragment } from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { signoutUser } from "../redux/actions/userActions";
import { Button } from "@material-ui/core";

const styles = {};

class SignOut extends Component {
  handleClick = () => {
    this.props.signoutUser();
    window.location.href = "/signin";
  }
  render() {
    return (
      <Fragment>
        <Button color="inherit" onClick={this.handleClick}>
          Sign Out
        </Button>
      </Fragment>
    );
  }
}

SignOut.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  signoutUser: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authenticated: state.user.authenticated,
});

export default connect(mapStateToProps, { signoutUser })(
  withStyles(styles)(SignOut)
);
