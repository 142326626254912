import React, { Component, Fragment } from "react";
import PropTypes from "prop-types";
import withStyles from "@material-ui/core/styles/withStyles";

import { connect } from "react-redux";
import { createAccount } from "../redux/actions/dataActions";

import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import AddIcon from "@material-ui/icons/AddCircle";
import { IconButton } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";


const styles = {
  typography: {
    useNextVariants: true,
  },
  form: {
    textAlign: "center",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  textField: {
    margin: "20px auto 20px auto",
  },
  button: {
    marginTop: 20,
    marginBottom: 20,
    position: "relative",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 15,
  },
  progress: {
    position: "absolute",
  },
};

class NewAccount extends Component {
  state = {
    newAccountName: "",
    open: false,
    errors: {}
  };
  handleOpen = () => {
    this.setState({ open: true });
  };
  handleClose = () => {
    this.setState({ newAccountName: "", open: false });
  };
  handleChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value
    })
  }
  handleSubmit = (event) => {
    event.preventDefault();
    this.props.createAccount({accountName: this.state.newAccountName});
    this.handleClose();
  }
  render() {
    const { classes } = this.props;
    const { errors } = this.state;
    return (
      <Fragment>
        <Tooltip title="Create account" placement="top">
          <IconButton onClick={this.handleOpen} className={classes.button}  >
            <AddIcon color="primary" style={{ fontSize: 60 }}></AddIcon>
          </IconButton>
        </Tooltip>
        <Dialog
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth
          maxWidth="sm"
        >
          <DialogTitle>Create a new account</DialogTitle>
          <DialogContent>
            <form onSubmit={this.handleSubmit}>
              <TextField
                name="newAccountName"
                type="text"
                label="Account name"
                placeholder="Name of the new account"
                className={classes.textField}
                value={this.state.newAccountName}
                onChange={this.handleChange}
                fullWidth
              />
            </form>
            {errors.general && (
              <Typography variant="body2" className={classes.customError}>
                {errors.general}
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="primary">
              Cancel
            </Button>
            <Button type="submit" onClick={this.handleSubmit} color="primary">
              Create
            </Button>
          </DialogActions>
        </Dialog>
      </Fragment>
    );
  }
}

NewAccount.propTypes = {
  createAccount: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  newAccountName: state.newAccountName
});

export default connect(mapStateToProps, { createAccount })(
  withStyles(styles)(NewAccount)
);
