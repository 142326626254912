import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import List from "@material-ui/core/List"

import Accountsummary from "../components/Accountsummary";
import NewAccount from "../components/NewAccount";

import { connect } from "react-redux";
import { createAccount } from "../redux/actions/dataActions";
import { getAccounts } from "../redux/actions/dataActions";




const styles = {
  typography: {
    useNextVariants: true,
  },
  form: {
    textAlign: "center",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  textField: {
    margin: "20px auto 20px auto",
  },
  button: {
    marginTop: 20,
    position: "relative",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progress: {
    position: "absolute",
  },
};

class home extends Component {
  componentDidMount() {
    this.props.getAccounts();
  }
  handleNewAccount() {
    this.props.createAccount();
  }



  render() {
    const {
      classes,
      accounts,
      loading,
      authenticated,
    } = this.props;
    
    
    // let accountsMarkup = `the value of loading is ${loading}`;
    let accountsMarkup = !loading ? (
      accounts.map((account) => <Accountsummary key={account.accountId} account={account} />)
    ) : (
      <p>Loading...</p>
    );

    let welcomeMessage = (
      <Grid container className={classes.form} spacing={2} maxwidth="sm">
      <Grid item xs={12} sm />
      <Grid item xs={12} sm>
        <p>Welcome to the MommyBank.  The place where you can keep track of all your personal transactions!</p>
      </Grid>
      <Grid item xs={12} sm />
    </Grid>
     );
  

    return (
       authenticated ? (<div>
      <Grid container className={classes.form} spacing={2} maxwidth="sm">
        <Grid item xs={12} sm />
        <Grid item xs={12} sm>
          <NewAccount/>
        </Grid>
        <Grid item xs={12} sm />
      </Grid>
      <Grid container maxWidth="sm">
      <Grid item xs={12} sm />
        <Grid item xs={12} sm >
          <List>
          {accountsMarkup}
          </List>
        </Grid>
        <Grid item xs={12} sm />
      </Grid>
      </div>) : (
        welcomeMessage
      )
    
    );
  }
}

home.propTypes = {
  getAccounts: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  accounts: state.data.accounts,
  loading: state.data.loading,
  authenticated: state.user.authenticated,
});

const mapActionsToProps = {
  createAccount,
  getAccounts
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(home));
