import {
  SET_ACCOUNTS,
  SET_ACCOUNT,
  LOADING_DATA,
  CREATE_ACCOUNT,
  SUBMIT_TRANSACTION,
  SET_TRANSACTION,
  SET_TRANSACTIONS,
} from "../types";

const initialState = {
  accounts: [],
  account: {},
  loading: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOADING_DATA:
      return {
        ...state,
        loading: true,
      };
    case SET_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
        loading: false,
      };
    case SET_ACCOUNT:
      return {
        ...state,
        account: action.payload,
      };
    case CREATE_ACCOUNT:
      return {
        ...state,
        accounts: [action.payload, ...state.accounts],
      };
    case SUBMIT_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
        transactions: [action.payload, ...state.transactions],
        account: {
          ...state.account,
          accountBalance: state.account.accountBalance + action.payload.amount,
        },
      };
    case SET_TRANSACTION:
      return {
        ...state,
        transaction: action.payload,
      };
    case SET_TRANSACTIONS:
      return {
        ...state,
        transactions: action.payload,
      };
    default:
      return state;
  }
}
