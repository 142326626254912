import React, { Component } from "react";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { connect } from "react-redux";
import {
  getAccount,
  submitTransaction,
  getTransactions,
} from "../redux/actions/dataActions";
import NewTransaction from "../components/NewTransaction";
import {
  Table,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
  TableCell,
  Typography,
  Card,
  CardContent,
  Button,
  } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";


const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: theme.palette.common.black,
    color: theme.palette.common.white,
  },
}))(TableCell);

const styles = {
  typography: {
    useNextVariants: true,
  },
  form: {
    textAlign: "center",
  },
  pageTitle: {
    margin: "20px auto 20px auto",
  },
  textField: {
    margin: "20px auto 20px auto",
  },
  button: {
    margin: 20,
    position: "relative",
    color: "primary",
  },
  ArrowBackIcon: {
    color: "primary",
  },
  NewTransaction: {
    margin: "20px 20px 20px 20px",
    position: "relative",
  },
  customError: {
    color: "red",
    fontSize: "0.8rem",
    marginTop: 10,
  },
  progress: {
    position: "absolute",
  },
};

class account extends Component {
  componentDidMount() {
    let accountId = this.props.match.params.accountId;
    this.setState({ accountId: accountId });
    console.log(encodeURIComponent(accountId));
    this.props.getAccount(accountId);
    this.props.getTransactions(accountId);
  }
  render() {
    const { classes, transactions, loading, account } = this.props;

    let transactionsMarkup =
      !loading && transactions ? (
        transactions.length > 0 ? (
          transactions.map((transaction) => (
            <StyledTableRow key={transaction.transactionId}>
              <StyledTableCell component="th" scope="row">
                {new Date(
                  transaction.transactionDate._seconds * 1000
                ).toLocaleDateString("en-US", {
                  day: "numeric",
                  month: "short",
                  year: "numeric",
                })}
              </StyledTableCell>
              <StyledTableCell>{transaction.description}</StyledTableCell>
              <StyledTableCell align="right">
                {transaction.amount.toLocaleString("en-US", {
                  style: "currency",
                  currency: "USD",
                })}
              </StyledTableCell>
            </StyledTableRow>
          ))
        ) : (
          <StyledTableRow key="notransactions">
            <StyledTableCell component="th" scope="row">
              {new Date().toLocaleDateString("en-US", {
                day: "numeric",
                month: "short",
                year: "numeric",
              })}
            </StyledTableCell>
            <StyledTableCell>Account has no transactions</StyledTableCell>
            <StyledTableCell align="right"></StyledTableCell>
          </StyledTableRow>
        )
      ) : (
        <p>Loading...</p>
      );
    return (
      <div>
        <Grid container className={classes.form} spacing={2} maxwidth="sm">
          <Grid item xs={12} sm />
          <Grid item xs={12} sm>
            <Grid container justify="center" spacing={3}>
              <Grid item>
                <Link to="/">
                  <Button
                    variant="contained"
                    className={classes.button}
                    color="primary"
                  >
                    <ArrowBackIcon />
                  </Button>
                </Link>
              </Grid>

              <Grid item>
                <NewTransaction />
              </Grid>
            </Grid>
            <Card className={classes.card}>
              <div className={classes.cardDetails}>
                <CardContent>
                  <Typography component="h2" variant="h5">
                    {account.accountName}
                  </Typography>
                  <Typography variant="subtitle1">
                    Balance: <span></span>
                    {account.accountBalance
                      ? account.accountBalance.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                        })
                      : "$--.--"}
                  </Typography>
                </CardContent>
              </div>
            </Card>
          </Grid>
          <Grid item xs={12} sm />
        </Grid>
        <Grid container maxWidth="sm">
          <Grid item xs={12} sm />
          <Grid item xs={12} sm>
            <TableContainer component={Paper}>
              <Table className={classes.table} aria-label="Transaction history">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Date</StyledTableCell>
                    <StyledTableCell>Description</StyledTableCell>
                    <StyledTableCell align="right">Amount</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>{transactionsMarkup}</TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={12} sm />
        </Grid>
      </div>
    );
  }
}

account.propTypes = {
  getTransactions: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  data: state.data,
  transactions: state.data.transactions,
  account: state.data.account,
  loading: state.data.loading,
});

const mapActionsToProps = {
  submitTransaction,
  getTransactions,
  getAccount,
};

export default connect(
  mapStateToProps,
  mapActionsToProps
)(withStyles(styles)(account));
